import { ds } from "js/core/models/dataService";
import { _, $ } from "js/vendor";
import firebase from "js/firebase";
import { app } from "js/namespaces";
import { Slide } from "js/core/models/slide";
import { Presentation } from "js/core/models/presentation";
import { Theme } from "js/core/models/theme";
import { timeoutRace } from "js/core/utilities/promiseHelper";
import FirebaseAdapter from "js/core/storage/firebaseAdapter";
import Adapter from "js/core/storage/adapter";
import { FeatureType } from "common/features";
import { OLD_BASIC_CUTOFF } from "common/constants";
import { appVersion } from "js/config";
import { getCanvasBundle } from "js/canvas";
import getLogger, { LogGroup } from "js/core/logger";

const noop = () => { };
const RENDER_TIMEOUT = 30 * 1000;

const logger = getLogger(LogGroup.RENDERER);

Adapter.setDefaultClass(FirebaseAdapter, { autoSync: false });

class ThumbnailRenderer {
    static get CANVAS_WIDTH() {
        return 1280;
    }

    static get CANVAS_HEIGHT() {
        return 720;
    }

    constructor() {
        this.canvas = null;
    }

    initialize() {
        logger.info("initialize");

        app.user = app.user || { get: noop, getEmail: noop };

        // create a dummy dataservice
        ds.dummySetup();
    }

    async start({
        slideId,
        slideModel,
        slideIndex,
        theme,
        authToken,
        hideBaiBranding,
        hideSmartSlideWatermark,
        isTeamUser,
    }) {
        logger.info("start");
        this.startTime = new Date().valueOf();

        // login with token
        const { user } = await this.loginWithToken(authToken);
        app.user = {
            id: user.uid,
            get: noop,
            getEmail: noop,
            isOldBasicUser: () => {
                return new Date(user.metadata.creationTime).getTime() < OLD_BASIC_CUTOFF;
            },
            features: {
                isFeatureEnabled(feature) {
                    if (feature === FeatureType.REMOVE_BAI_BRANDING) {
                        return hideBaiBranding;
                    } else if (feature === FeatureType.SMART_SLIDES) {
                        return hideSmartSlideWatermark;
                    } else if (feature === FeatureType.TEAMS) {
                        return isTeamUser;
                    } else {
                        return true;
                    }
                }
            }
        };
        // load presentation
        const presentation = await this.loadPresentation(theme, user.uid);
        // load slide
        const slide = await this.loadSlide(slideId, slideModel, presentation);
        // load theme
        await app.themeManager.loadTheme(presentation);
        // render canvas
        await timeoutRace(
            RENDER_TIMEOUT,
            this.render(slide, slideIndex)
        );
        return { stagesCount: this.canvas.playbackStages.length || 1 };
    }

    loginWithToken(authToken) {
        logger.info("loginWithToken");
        return firebase.auth().signInWithCustomToken(authToken);
    }

    async loadSlide(slideId = null, slideModel = null, presentation) {
        logger.info("loadSlide");

        if (slideModel) {
            return new Slide(slideModel, { disconnected: true, presentation });
        }

        if (slideId) {
            const slide = new Slide(
                {
                    id: slideId
                },
                {
                    autoSync: false,
                    presentation,
                }
            );
            await slide.load();
            return slide;
        }

        throw new Error("slideId or slideModel required");
    }

    async loadPresentation(theme, userUid) {
        logger.info("loadPresentation");

        // Creating an ephemerial presentation
        const presentation = new Presentation(
            {
                id: _.uniqueId(),
                userId: userUid
            },
            { disconnected: true, autoLoad: false }
        );
        presentation.permissions.write = true;
        // Assigning the theme
        await app.themeManager.saveThemeToPresentation(new Theme(theme, { disconnected: true, autoLoad: false }), presentation);
        // Setting the presentation as current presentation
        ds.selection.presentation = presentation;

        return presentation;
    }

    async render(slide, slideIndex) {
        logger.info("render");

        // Load slide canvas bundle
        const { SlideCanvas } = await getCanvasBundle(slide.get("version") ?? appVersion);

        this.canvas = new SlideCanvas({
            dataModel: slide,
            canvasWidth: ThumbnailRenderer.CANVAS_WIDTH,
            canvasHeight: ThumbnailRenderer.CANVAS_HEIGHT,
            editable: false,
            isPlayback: true,
            isRenderingTemplateThumbnail: slide.get("isTeamSlideTemplate"),
            noThumbnail: true,
            slideIndex,
            generateImages: false
        });
        app.currentCanvas = this.canvas;

        const scale = Math.min(
            window.innerWidth / ThumbnailRenderer.CANVAS_WIDTH,
            window.innerHeight / ThumbnailRenderer.CANVAS_HEIGHT
        );
        this.canvas.canvasScale = scale;

        // render and layout the canvas
        $("body").append(this.canvas.render().$el);
        this.canvas.$el.css({
            position: "absolute",
            top: 0,
            left: 0,
            transform: `scale(${scale})`
        });

        await this.canvas.renderSlide();
    }

    waitForImagesToLoad() {
        return this.canvas.waitForImages();
    }

    getSlideNotes() {
        const slideNotes = [];

        if (this.canvas.hasSlideNotes()) {
            slideNotes.push(this.canvas.getNormalizedSlideNotes());
        }

        this.canvas.getElements().forEach(element => {
            if (element.publicVideoUrl) {
                slideNotes.push(`Video URL: ${element.publicVideoUrl}`);
            }
        });

        return slideNotes.join("\n");
    }

    getSlideModifiedAtValue() {
        return this.canvas.dataModel.get("modifiedAt") ?? Date.now();
    }

    async completeTask() {
        logger.info("completeTask");
        this.canvas.remove();
        await firebase.auth().signOut();
    }
}

window.thumbnailRenderer = new ThumbnailRenderer();
